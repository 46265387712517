<template>
	<form class="form form--profile form-profile" @submit.prevent.stop="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">

			<h1 class="form-section__title">Contact Information</h1>
			<p class="form-section__description">Update your contact information.</p>

			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="First Name" autocomplete="given-name" v-model="fields.firstName" />
				<text-field class="form__field" label="Last Name" autocomploete="family-name" v-model="fields.lastName" />
				<text-field class="form__field" label="City" v-model="fields.address.city" />
				<select-field class="form__field" label="State" v-model="fields.address.state" :options="states" />
				<text-field class="form__field" label="Postal Code" v-model="fields.address.postal" />
				<text-field class="form__field" type="tel" label="Phone" v-model="fields.phone" />
			</div>

			<div class="form__actions form__actions--horizontal">
				<mdc-button class="mdc-button--unelevated form__action" type="submit" :disabled="isLoading">Update Profile</mdc-button>
			</div>

		</div>

	</form>
</template>

<script>
import MdcButton from '@/components/MdcButton'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'
import stateList from '@/assets/stateList.json'

export default {
	name: 'FormProfile',
	components: {
		MdcButton,
		LoadingSpinner,
		SelectField,
		TextField,
	},
	props: {
		user: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		isLoading: false,
		defaults: {
			address: {},
		},
		fields: {},
		states: stateList,
	}),
	methods: {
		submitForm(fields) {
			this.isLoading = true
			this.$store.dispatch('user/updateMe', fields)
				.then(() => {
					this.$notice(`Your profile has been updated!`)
				})
				.catch((error) => {
					this.$notice(`ERROR: Could not update profile`)
				})
				.finally(() => {
					this.isLoading = false
				})
		},
	},
	watch: {
		user: {
			immediate: true,
			handler: function (userProp) {
				this.fields = {
					...this.defaults,
					...userProp,
				}
			},
		},
	},
}
</script>

<style scope lang="scss">

</style>
