<template>
	<form class="form form--password-reset form-password-reset" @submit.prevent.stop="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<h1 class="form-section__title">Reset Your Password</h1>
			<p class="form-section__description">A confirmation email will be sent to continue your password reset.</p>

			<div class="form-row">
				<text-field class="form__field" v-model="fields.password" label="Current Password" type="password" :required="true" />
			</div>

			<div class="form-row">
				<text-field class="form__field" v-model="fields.newPassword" label="New Password" type="password" :minlength="8" :required="true" />
				<div class="form__hint">
					Your password must be a minimum 8 characters and include three of these four character sets: uppercase, lowercase, numbers and special characters.
				</div>
			</div>
			<div class="form-row">
				<text-field class="form__field" v-model="fields.newPasswordConfirm" label="Confirm New Password" type="password" :required="true" />
			</div>

			<div class="alert alert--fail" v-if="error">
				{{ error }}
			</div>

			<div class="form__actions form__actions--horizontal">
				<mdc-button class="mdc-button--unelevated form__action" type="submit" :disabled="isLoading">Reset Password</mdc-button>
			</div>

		</div>
	</form>
</template>

<script>
import MdcButton from '@/components/MdcButton'
import LoadingSpinner from '@/components/LoadingSpinner'
import TextField from '@/components/TextField'

export default {
	name: 'FormPasswordReset',
	components: {
		MdcButton,
		LoadingSpinner,
		TextField,
	},
	data: () => ({
		error: null,
		fields: {
			password: '',
			newPassword: '',
			newPasswordConfirm: '',
		},
		isLoading: false,
	}),
	methods: {
		submitForm(fields) {
			this.error = null
			if (!fields.password || !fields.newPassword || !fields.newPasswordConfirm) {
				return this.error = `All fields are required`
			}
			if ( !fields.newPassword.match(/[a-z]/g) ) {
				return this.error = `New password must contain lowercase letters`
			}
			if ( !fields.newPassword.match(/[A-Z]/g) ) {
				return this.error = `New password must contain uppercase letters`
			}
			if ( !fields.newPassword.match(/[0-9]/g) && !fields.newPassword.match(/[^a-zA-Z\d]/g) ) {
				return this.error = `New password must contain number or special character`
			}
			if (fields.newPassword.length < 8) {
				return this.error = `New password must be 8 or more characters`
			}
			if (fields.newPassword !== fields.newPasswordConfirm) {
				return this.error = 'Password confirmation does not match'
			}

			this.isLoading = true

			this.$store.dispatch('user/resetMe', fields)
				.then(() => {
					this.$notice(`Your password has been updated.`)
					this.fields = {}
				}).catch(error => {
					this.error = error
				}).finally(() => {
					this.isLoading = false
				})
		},
	},
}
</script>

<style scoped lang="scss">

</style>
