<template>
	<form class="form form--account">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<h1 class="form-section__title">Connect to Dr. Chrono</h1>
			<p class="form__description">To transfer patients directly to Dr. Chrono, log in to your Dr. Chrono account below.</p>
			<p v-if="authToken"><strong>Status: CONNECTED</strong></p>
			<a class="form__action mdc-button mdc-button--primary mdc-button--unelevated" :href="loginUrl">{{ authToken ? `Log In Again` : `Log In` }}</a>
		</div>
	</form>
</template>

<script>
import MdcButton from '@/components/MdcButton'

export default {
	name: 'ProviderDrChrono',
	components: {
		MdcButton,
	},
	computed: {
		loginUrl() {
			let url = ''
			const provider = this.$store.getters['user/provider']
			const { origin, pathname } = window.location
			const redirectUrl = [origin, pathname].join('')

			if (provider) {
				switch( provider.key) {
					case 'drchrono':
						url = `https://drchrono.com/o/authorize/?redirect_uri=${redirectUrl}&response_type=code&client_id=${provider.client_id}&scope=patients:read%20patients:write%20clinical:read%20clinical:write%20user:read%20user:write%20billing:patient-payment:read%20billing:patient-payment:write%20billing:read%20billing:write`
						break
				}
			}

			return url
		},
		authToken() {
			return this.$store.getters['drchrono/token']
		},
		isLoading() {
			return this.$store.getters['misc/loading']
		},
	},
	async beforeMount() {
		const { code } = this.$route.query
		if (code) {
			const provider = this.$store.getters['user/provider']
			if (provider) {
				switch( provider.key) {
					case 'drchrono':
						let res
						try {
							res = await this.$store.dispatch(`drchrono/auth`, code)
						} catch (error) {
							this.$notice(`ERROR: ${error.message || error}`)
						}

						if (res.access_token) {
							await this.$store.dispatch('drchrono/login', res)
						} else {
							this.$notice(`ERROR: Can not log in to Dr. Chrono. Please try again.`)
						}

						this.$router.replace({ name: 'account', query: {}})
						break
				}
			}
		}
	},
}
</script>

<style scoped lang="scss">

</style>
