<template>
	<button class="mdc-button" v-bind="$attrs" v-on="$listeners">
		<span class="mdc-button__ripple"></span>
		<span class="mdc-button__label"><slot /></span>
	</button>
</template>

<script>
export default {
	name: 'MdcButton',
}
</script>

<style scoped lang="scss">

</style>
