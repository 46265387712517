<template>
	<div class="card" :class="{'card--loading':loading}">
		<div class="card__loader" v-if="loading">
			<loading-spinner />
		</div>
		<slot />
	</div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
	name: 'card',
	components: {
		LoadingSpinner,
	},
	props: {
		loading: Boolean,
	},
}
</script>

<style scoped lang="scss">
@use '@material/elevation';
.card {
	$card: &;
	@include modules.gutter('margin-bottom');
	background-color: modules.color_('background');
	border-radius: modules.$border-radius;
	box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
	position: relative;

	@media print {
		box-shadow: none;
		// page-break-inside: avoid;
	}

	&::v-deep {
		#{$card}__title {
			@include modules.gutter('margin', 0);
			@include modules.gutter('padding');
			display: block;
			font-family: modules.$font-family-secondary;
			font-size: 18px;
			font-weight: 700;
			letter-spacing: 0.035em;
			width: 100%;
		}

		#{$card}__subtitle {
			@include modules.gutter('padding-left');
			font-family: modules.$font-family-secondary;
			font-weight: 500;
			letter-spacing: 0.5px;
		}

		#{$card}__fields {
			@include modules.gutter('padding');
			@include modules.gutter('padding-top', 0);
		}

		#{$card}__field {
			@include modules.gutter('padding', 0.5);
			align-items: flex-start;
			border-bottom: 1px solid modules.color_('border');
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			&:first-of-type {
				border-top: 1px solid modules.color_('border');
			}

			&-label {
				font-weight: 600;
			}
		}
	}

	&__loader {
		align-items: center;
		background-color: rgba(225, 225, 225, 0.35);
		border-radius: modules.$border-radius;
		display: flex;
		justify-content: center;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
	}
}
</style>
