<template>
	<form class="form form--email form-email" @submit.prevent.stop="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">

			<h1 class="form-section__title">Email Address</h1>
			<p class="form-section__description">Email updates will send a confirmation before changing.</p>

			<div class="form-row">
				<text-field type="email" class="form__field" label="Current Email Address" v-model="fields.email" :disabled="true" />
				<text-field type="password" class="form__field" name="password" autocomplete="password" label="Confirm Password" v-model="fields.password" />
				<text-field type="email" class="form__field" name="new_email" autocomplete="email" label="New Email Address" v-model="fields.newEmail" />
			</div>

			<div class="form__actions form__actions--horizontal">
				<mdc-button class="mdc-button--unelevated form__action" type="submit" :disabled="isLoading">Update Email Address</mdc-button>
			</div>

		</div>
	</form>
</template>

<script>
import MdcButton from '@/components/MdcButton'
import LoadingSpinner from '@/components/LoadingSpinner'
import TextField from '@/components/TextField'

export default {
	name: 'FormEmail',
	components: {
		MdcButton,
		LoadingSpinner,
		TextField,
	},
	props: {
		user: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		fields: {},
		isLoading: false,
	}),
	methods: {
		submitForm(fields) {
			this.isLoading = true
			this.$store.dispatch(`user/updateEmail`, fields)
				.then((res) => {
					this.$notice(`Your email address change has been requested. Please check your email.`)
				})
				.catch((error) => {
					this.$notice(`ERROR: ${ error.message || error }`)
				})
				.finally(() => {
					this.isLoading = false
				})
		},
	},
	watch: {
		user: {
			immediate: true,
			handler: function (userProp) {
				this.fields = {
					...this.defaults,
					...userProp,
				}
			},
		},
	},
}
</script>

<style scoped lang="scss">

</style>
