<template>
	<div class="partial partial--account account">
		<div class="partial__heading">
			<h1 class="partial__title">Account</h1>
			<div class="partial__actions">
			</div>
		</div>
		<div class="partial__body">
			<card v-if="provider.key == 'drchrono'">
				<dr-chrono-account />
			</card>
			<card>
				<form-email />
			</card>
			<card>
				<form-password-reset />
			</card>
			<card>
				<form-profile />
			</card>
		</div>
	</div>
</template>


<script>
import Card from '@/components/Card'
import FormEmail from '@/components/FormEmail'
import FormPasswordReset from '@/components/FormPasswordReset'
import FormProfile from '@/components/FormProfile'
import DrChronoAccount from '@/components/providers/DrChronoAccount.vue'

export default {
	name: 'PartialAccount',
	components: {
		Card,
		FormEmail,
		FormPasswordReset,
		FormProfile,
		DrChronoAccount,
	},
	computed: {
		profile() {
			return this.$store.getters['user/me']
		},
		provider() {
			return this.$store.getters['user/provider']
		}
	}
}
</script>

<style scoped lang="scss">
.partial__body {
	display: grid;
	grid-template-columns: 1fr 1fr;
	@include modules.gutter('grid-column-gap');
}
</style>
